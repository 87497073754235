import { Breadcrumb } from "antd";
import { EditTypeInstanceContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { Link, useNavigate, useParams } from "react-router-dom";

function EditTypeInstance() {
  const navigate = useNavigate();
  const { id } = useParams();

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/typeinstances">TypeInstances</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Edit</Breadcrumb.Item>
    </Breadcrumb>
  );

  const backToList = () => {
    navigate(`/typeinstances`);
  };

  return (
    <Page
      breadcrumb={breadcrumb}
      title="Edit TypeInstance"
      onBack={() => window.history.back()}
    >
      <EditTypeInstanceContainer typeInstanceID={id} backToList={backToList} />
    </Page>
  );
}

export default EditTypeInstance;
