import React from "react";

import { Layout } from "antd";
import { loadRuntimeConfig } from "../config/runtime";
const { Footer: AntFooter } = Layout;

const current_year = new Date().getFullYear();
const { imageTag } = loadRuntimeConfig();

function Footer() {
  return (
    <AntFooter style={{ textAlign: "center", userSelect: "none" }}>
      &copy;{` ${current_year}  SuperMaestro ${imageTag}`} 
    </AntFooter>
  );
}

export default Footer;
