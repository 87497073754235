import React from "react";
import "./App.css";

import NewAction from "./routes/new-action";
import Actions from "./routes/actions";
import TypeInstances from "./routes/typeinstances";
import Action from "./routes/action";
import Policy from "./routes/policy";
import Role from "./routes/role";
// import Workflow from "./routes/workflow";
import { Navigate, Route, Routes } from "react-router-dom";
import InterfaceGroupCatalog from "./routes/hub-interface-groups";
import InterfacesCatalog from "./routes/hub-interfaces";
import ComposedActions from "./routes/composed-actions";
import NewComposedAction from "./routes/new-composed-action";
import ComposedAction from "./routes/composed-action";
import { Layout } from "antd";
import Menu from "./layout/Menu";
import NotFoundPage from "./layout/NotFound";
import {
  RequireAuthContainer as BasicAuthContainer,
  OidcAuthContainer,
  LoginDetails,
} from "@capactio/react-components";
import Header from "./layout/Header";
import { loadRuntimeConfig } from "./config/runtime";
import NewTypeInstance from "./routes/new-typeinstance";
import EditTypeInstance from "./routes/edit-typeinstance";

function App() {
  const { defaultGatewayEndpoint, oidcConfig } = loadRuntimeConfig();
  const defaultLoginDetails: LoginDetails = {
    endpoint: defaultGatewayEndpoint,
    username: "",
    password: "",
  };

  const container = (
    <Layout>
      <Header />
      <Layout>
        <Menu />
        <Routes>
          <Route path="/" element={<Navigate to="/hub/interface-groups" />} />
          <Route path="/hub/interface-groups">
            <Route index element={<InterfaceGroupCatalog />} />
            <Route path=":name" element={<InterfacesCatalog />} />
          </Route>
          <Route path="actions">
            <Route index element={<Actions />} />
            <Route path="new/:path/:revision" element={<NewAction />} />
            <Route path=":name" element={<Action />} />
          </Route>
          {/* <Route path="/workflow">
              <Route index element={<Workflow />} />
            </Route> */}
          <Route path="/typeinstances">
            <Route index element={<TypeInstances />} />
            <Route path=":id" element={<InterfacesCatalog />} />
            <Route path="new" element={<NewTypeInstance />} />
            <Route path="edit/:id" element={<EditTypeInstance />} />
          </Route>
          <Route path="/policy">
            <Route index element={<Policy />} />
          </Route>
          <Route path="/composed-actions">
            <Route index element={<ComposedActions />} />
            <Route path="new" element={<NewComposedAction />} />
            <Route path=":name" element={<ComposedAction />} />
          </Route>
          <Route path="/role">
            <Route index element={<Role />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </Layout>
  );

  return oidcConfig ? (
    <OidcAuthContainer
      defaultLoginDetails={{
        endpoint: defaultGatewayEndpoint,
        config: oidcConfig,
      }}
    >
      {container}
    </OidcAuthContainer>
  ) : (
    <BasicAuthContainer defaultLoginDetails={defaultLoginDetails}>
      {container}
    </BasicAuthContainer>
  );
}

export default App;
