import { useContext } from "react";
import "./Header.css";
import type { MenuProps } from "antd";
import { Layout, Menu, notification } from "antd";
import { AuthContext } from "@capactio/react-components";
import { SUPERMAESTRO_ROLES } from "@capactio/react-components";
import {
  FileTextOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import _ from "lodash";

const { Header: AntHeader } = Layout;
const SUPEADMIN_ROLES = SUPERMAESTRO_ROLES.SuperAdmin;

function Header() {
  const authCtx = useContext(AuthContext);
  const openNotification = () => {
    notification.open({
      message: "Your role list",
      description: _.map(authCtx.userRoles, (o) => {
        return (
          <div>
            {o}
            <br />
          </div>
        );
      }),
    });
  };
  const showRoleItem: boolean = authCtx.userRoles?.length
    ? authCtx.userRoles?.length > 1
    : false;
  const isAdmin: boolean = _.indexOf(authCtx.userRoles, SUPEADMIN_ROLES) > -1;
  const getTitle = () => {
    if (authCtx.userName !== undefined && isAdmin)
      return `${authCtx.userName} - ${SUPEADMIN_ROLES}`;
    else if (authCtx.userRoles && authCtx.userName !== undefined)
      return `${authCtx.userName} - ${authCtx.userRoles[0]}`;
    else if (authCtx.userName === undefined) return `User - ${SUPEADMIN_ROLES}`;
    else return "User";
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <a
          href="https://docs.supermaestro.nordcloudapp.com"
          target="_blank"
          rel="noreferrer"
        >
          Documentation
        </a>
      ),
      key: "docs",
      icon: <FileTextOutlined />,
    },
    {
      label: getTitle(),
      key: "account",
      children: [
        {
          label: (
            <a
              href="https://github.com/nordcloud/supermaestro/blob/master/docs/user-permission-request.md"
              target="_blank"
              rel="noreferrer"
            >
              Request Permission
            </a>
          ),
          key: "permission-docs",
          icon: <QuestionCircleOutlined />,
        },
        ...(showRoleItem
          ? [
              {
                label: "Roles",
                key: "roles",
                icon: <UserSwitchOutlined />,
                onClick: () => openNotification(),
              },
            ]
          : []),
        {
          label: "Logout",
          key: "logout",
          icon: <LogoutOutlined />,
          onClick: () => authCtx.logout(),
        },
      ],
    },
  ];

  return (
    <AntHeader className="header">
      <Link className="header-logo" to="/">
        <img
          className="header-logo-image"
          src="/logo_nordcloud.svg"
          alt="Nordcloud Logo"
        />
        <b className="header-logo-title">SuperMaestro</b>
      </Link>
      <Menu
        theme="dark"
        mode="horizontal"
        className="header-menu"
        items={items}
      />
    </AntHeader>
  );
}

export default Header;
