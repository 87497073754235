import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { RoleContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { loadRuntimeConfig } from "../config/runtime";

function Role() {

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/role">Role</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const { queryRefetchIntervalMS } = loadRuntimeConfig();


  return (
    <Page breadcrumb={breadcrumb} title="Role Configuration">
      <RoleContainer 
        refetchInterval={queryRefetchIntervalMS}
      />
    </Page>
  );
}

export default Role;
