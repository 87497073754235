import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import { ComposedActionDetailsContainer } from "@capactio/react-components";
import Page from "../layout/Page";

function ComposedAction() {
  const navigate = useNavigate();
  const { name } = useParams();

  const composedActionName = name ?? "";
  if (composedActionName === "") {
    return <p>Composed Action name cannot be empty</p>;
  }

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/composed-actions">Composed Actions</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{composedActionName}</Breadcrumb.Item>
    </Breadcrumb>
  );

  /** change detail api refresh interval to 5s*/
  const queryRefetchIntervalMS = 5000;

  const onDeleteComposedAction = (_: string) => {
    navigate("/composed-actions");
  };
  const onCancelComposedAction = (_: string) => {
    navigate("/composed-actions");
  }

  return (
    <Page breadcrumb={breadcrumb} title="Composed Action details">
      <ComposedActionDetailsContainer
        name={composedActionName}
        refetchInterval={queryRefetchIntervalMS}
        onDeleteComposedAction={onDeleteComposedAction}
        onCancelComposedAction={onCancelComposedAction}
      />
    </Page>
  );
}

export default ComposedAction;
