import { OidcConfig } from "@capactio/react-components";
import { WebStorageStateStore } from "oidc-client-ts";
interface ExtendedWindow extends Window {
  RuntimeConfig?: RuntimeConfig;
}

declare const window: ExtendedWindow;

export interface RuntimeConfig {
  defaultGatewayEndpoint: string;
  argoWorkflowsUIBaseURL: string;
  queryRefetchIntervalMS: number;
  oidcConfig?: OidcConfig;
  imageTag: string;
}

const defaultRuntimeConfig: RuntimeConfig = {
  defaultGatewayEndpoint:
    "https://gateway.dev.supermaestro.dev.nordcloudapp.com/graphql",
  queryRefetchIntervalMS: 60000,
  argoWorkflowsUIBaseURL: "https://workflow.capact.local/",
  imageTag: process.env.IMAGE_TAG ?? "",
};

export function loadRuntimeConfig(): RuntimeConfig {
  if (!window.RuntimeConfig) {
    return defaultRuntimeConfig;
  }

  const { oidcConfig, ...runtimeConfig } = window.RuntimeConfig;

  return {
    ...defaultRuntimeConfig,
    ...runtimeConfig,
    ...(oidcConfig && {
      oidcConfig: {
        ...oidcConfig,
        userStore: new WebStorageStateStore({ store: localStorage }),
      },
    }),
  };
}
