import { Breadcrumb } from "antd";
import { CreateTypeInstanceContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { Link, useNavigate } from "react-router-dom";

function NewTypeInstance() {
  const navigate = useNavigate();
  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/typeinstances">TypeInstances</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>New</Breadcrumb.Item>
    </Breadcrumb>
  );

  const backToList = () => {
    navigate(`/typeinstances`);
  };

  return (
    <Page
      breadcrumb={breadcrumb}
      title="Create a new TypeInstance"
      onBack={() => window.history.back()}
    >
      <CreateTypeInstanceContainer backToList={backToList} />
    </Page>
  );
}

export default NewTypeInstance;
