import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import { CreateComposedActionContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { loadRuntimeConfig } from "../config/runtime";

function NewComposedAction() {
  const navigate = useNavigate();

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/composed-actions">Composed Actions</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>New</Breadcrumb.Item>
    </Breadcrumb>
  );

  const onComposedActionCreate = () => {
    navigate(`/composed-actions`);
  };

  const { queryRefetchIntervalMS } = loadRuntimeConfig();
  return (
    <Page
      breadcrumb={breadcrumb}
      title="Create a new Composed Action"
      onBack={() => window.history.back()}
    >
      <CreateComposedActionContainer
        refetchInterval={queryRefetchIntervalMS}
        onComposedActionCreate={onComposedActionCreate}
      />
    </Page>
  );
}

export default NewComposedAction;
