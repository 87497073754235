import React from "react";
import { Breadcrumb, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AllTypeInstanceListContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { loadRuntimeConfig } from "../config/runtime";
import { PlusOutlined } from "@ant-design/icons";

function TypeInstances() {
  const navigate = useNavigate();
  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/typeinstances">TypeInstances</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const { queryRefetchIntervalMS } = loadRuntimeConfig();

  const onCreateTypeInstance = () => {
    navigate(`/typeinstances/new`);
  };

  const onDeleteTypeInstance = () => {
    window.location.reload();
  };

  const onEditTypeInstance = (typeInstanceID: string) => {
    navigate(`/typeinstances/edit/${typeInstanceID}`);
  };

  const createButton = (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={onCreateTypeInstance}
    >
      Create a New TypeInstance
    </Button>
  );

  return (
    <Page
      breadcrumb={breadcrumb}
      title="TypeInstances List"
      extra={createButton}
    >
      <AllTypeInstanceListContainer
        refetchInterval={queryRefetchIntervalMS}
        onDeleteAction={onDeleteTypeInstance}
        onEditAction={onEditTypeInstance}
      />
    </Page>
  );
}

export default TypeInstances;
