import React from "react";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { ComposedActionListContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { loadRuntimeConfig } from "../config/runtime";

function ComposedActions() {
  const navigate = useNavigate();
  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/composed-actions">Composed Actions</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const { queryRefetchIntervalMS } = loadRuntimeConfig();

  const onCreateComposedActionClick = () => {
    navigate(`/composed-actions/new`);
  };

  const onComposedActionItemClick = (name: string) => {
    navigate(`/actions/${name}`);
  }

  const onDeleteAction = (name: string) => {
    window.location.reload();
  };

  const onCancelAction = (name: string) => {
    window.location.reload();
  };

  return (
    <Page breadcrumb={breadcrumb} title="Composed Actions">
      <ComposedActionListContainer
        refetchInterval={queryRefetchIntervalMS}
        onCreateComposedActionClick={onCreateComposedActionClick}
        onComposedActionItemClick={onComposedActionItemClick}
        onDeleteAction={onDeleteAction}
        onCancelAction={onCancelAction}
      />
    </Page>
  );
}

export default ComposedActions;
