import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { ListPolicyContainer } from "@capactio/react-components";
import Page from "../layout/Page";
import { loadRuntimeConfig } from "../config/runtime";

function Policy() {

  const breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/policy">Policy</Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  const { queryRefetchIntervalMS } = loadRuntimeConfig();


  return (
    <Page breadcrumb={breadcrumb} title="Policy Detail">
      <ListPolicyContainer 
        refetchInterval={queryRefetchIntervalMS}
      />
    </Page>
  );
}

export default Policy;
